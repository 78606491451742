/* .apexcharts-legend-text {
  color: #f1f1f1 !important;
} */

.apexcharts-canvas {
  color: #000 !important;
}

/* .apexcharts-tooltip {
    background: #f3f3f3;
    color: orange;
  } */

.apexcharts-menu-item {
  color: #000 !important; /* Define a cor da fonte como preto */
}
